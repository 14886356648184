import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//

import Register from './pages/Register';
import NotFound from './pages/Page404';
import WWCPortal from './pages/wwcPortal/WCCPortal';
import PortalLogin from './pages/PortalLogin';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/wwc-portal',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: ':slug/dashboard', element: <WWCPortal /> }
      ]
    },

    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        {element: <Navigate to='/login/:slug' replace />},
        { path: '/login', element: <PortalLogin /> },
        { path: '/login/:slug', element: <PortalLogin /> },
        { path: '/', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
