import React, { useEffect, useState } from 'react';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import plusFill from '@iconify/icons-ant-design/user-add';
import leftArrowFill from '@iconify/icons-eva/arrow-left-fill';
import shareFill from '@iconify/icons-eva/share-fill';
import { Link as RouterLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import viewMore from '@iconify/icons-eva/person-outline';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  Modal,
  Backdrop,
  Fade,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Snackbar,
  Alert
} from '@mui/material';
// components
import { getDatabase, ref, child, get, onValue } from 'firebase/database';
import app from '../../config/database/firebase';
import cardOutline from '@iconify/icons-eva/credit-card-outline';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
//
import USERLIST from '../../_mocks_/user';
import WWCMore from 'src/components/_dashboard/user/WWCMore';
import WWCListHead from 'src/components/_dashboard/user/WWCListHead';
import WWCToolbar from 'src/components/_dashboard/user/WWCToolbar';
import { Box, color } from '@mui/system';
import closeCircleOutline from '@iconify/icons-eva/close-circle-outline';
import WWCProfile from 'src/components/wwc/WWCProfile';
import WWCCardImage from 'src/components/wwc/WWCCardImage';
import wwc from '../../assets/img/wwc.png';
import { RegisterFormPortal } from 'src/components/authentication/register';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'dob', label: 'Date of Birth', alignRight: false },
  { id: 'emStatus', label: 'Employment Status', alignRight: false },
  { id: 'emType', label: 'Employment Type', alignRight: false },
  { id: 'position', label: 'Position', alignRight: false },
  { id: 'category', label: 'Category', alignRight: false },
  { id: 'renNumber', label: 'Renewal Number', alignRight: false },
  { id: 'cardNumber', label: 'WWC Card Number', alignRight: false },
  { id: 'expiryDate', label: 'Expiry Date', alignRight: false },
  { id: 'dateCompleted', label: 'Completed WWC Check', alignRight: false },
  { id: '' },
  { id: '' }
];

const TABLE_HEAD_FULL = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'dob', label: 'Date of Birth', alignRight: false },
  { id: 'emStatus', label: 'Employment Status', alignRight: false },
  { id: 'emType', label: 'Employment Type', alignRight: false },
  { id: 'position', label: 'Position', alignRight: false },
  { id: 'category', label: 'Category', alignRight: false },
  { id: 'exemptions', label: 'Exemptions', alignRight: false },
  { id: 'renNumber', label: 'Renewal Number', alignRight: false },
  { id: 'cardNumber', label: 'WWC Card Number', alignRight: false },
  { id: 'expiryDate', label: 'Expiry Date', alignRight: false },
  { id: 'startDate', label: 'Start Date', alignRight: false },
  { id: 'wwcCheckStatus', label: 'WWC Check Status', alignRight: false },
  { id: 'actionTaken', label: 'Action Taken', alignRight: false },
  { id: 'datePosReviewed', label: 'Date position reviewed', alignRight: false },
  { id: 'validationDates', label: 'Validation Dates', alignRight: false },
  { id: 'dateCompleted', label: 'Date Completed WWC Check', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '85%',
  bgcolor: 'background.paper',
  borderRadius: 3,
  boxShadow: 24,
  p: 4
};

const steps = ['User Details', 'Card Information', 'Card Details', 'Card Picture'];

const WCCPortal = ({ props }) => {
  const [step, setStep] = useState(0);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [showFullTable, setShowFullTable] = useState(true);
  const [userSelected, setUserSelected] = useState({});
  const [loading, setLoading] = useState(true);
  const [openRegister, setOpenRegister] = useState(false);
  const [copyAlert, setCopyAlert] = useState(false);

  const updateStep = () => {
    setStep(step + 1);
  };
  const backStep = () => {
    if (step != 0) {
      setStep(step - 1);
    }
  };

  const previousStep = () => {
    setStep(step - 1);
  };

  const { slug } = useParams();

  const [open, setOpen] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const handleOpen = (profile) => {
    setUserSelected(profile);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleOpenCard = (profile) => {
    setUserSelected(profile);
    setOpenCard(true);
  };
  const handleCloseCard = () => setOpenCard(false);

  const handleOpenRegister = () => setOpenRegister(true);
  const handleCloseRegister = () => setOpenRegister(false);

  const handleCopyOpen = () => setCopyAlert(true);
  const handleCloseCopy = () => setCopyAlert(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const [members, setMembers] = useState('');
  const [accountDetails, setAccountDetails] = useState('');

  const dbRef = ref(getDatabase(app));

  useEffect(() => {
    const db = getDatabase(app);
    const playerRef = ref(db, `WWC/portalAccounts/${slug}`);
    onValue(playerRef, (snapshot) => {
      if (snapshot.exists()) {
        setAccountDetails(snapshot.val());
        console.log(snapshot.val());
        setLoading(false);
      } else {
        console.log('No Account Found');
      }
    });
    const wwcRef = ref(db, `WWC/users`);
    onValue(wwcRef, (snapshot) => {
      if (snapshot.exists()) {
        setMembers({
          ...snapshot.val()
        });
      } else {
        console.log('No data available');
      }
    });
  }, []);

  const { accountName, organizationName, img, color, key } = accountDetails;
  console.log(accountDetails);

  const dataSource = Object.keys(members).map((dataIndex) => members[dataIndex]);

  dataSource.sort((a, b) => a.firstName.localeCompare(b.firstName));

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataSource.length) : 0;

  // console.log(dataSource);

  const filteredUsers = applySortFilter(dataSource, getComparator(order, orderBy), filterName);

  const [age, setAge] = React.useState('');

  for (let index = 0; index < filteredUsers.length; index += 1) {
    if (filteredUsers[index].organization != organizationName) {
      delete filteredUsers[index];
    }
  }

  console.log(filteredUsers);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const isUserNotFound = filteredUsers.length === 0;

  let navigate = useNavigate();

  const toggleFullscreen = () => {
    if (showFullTable) {
      setShowFullTable(false);
    } else {
      setShowFullTable(true);
    }
  };

  return (
    <Page title={`WWC | ${organizationName}`} style={loading ? { height: '100%' } : null}>
      <Snackbar open={copyAlert} autoHideDuration={6000} onClose={handleCloseCopy}>
        <Alert onClose={handleCloseCopy} severity="success" sx={{ width: '100%' }}>
          Registration link copied to keyboard!
        </Alert>
      </Snackbar>
      {/* MODAL */}
      {loading ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
              sx: {
                background:
                  'linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgba(22, 28, 36, 0.19) 100%)'
              }
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    View {userSelected.firstName} {userSelected.lastName}'s profile below
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <Tooltip title="Close">
                      <Icon icon={closeCircleOutline} />
                    </Tooltip>
                  </IconButton>
                </div>
                <WWCProfile profile={userSelected} />
              </Box>
            </Fade>
          </Modal>
          {/* CARD IMAGE */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openCard}
            onClose={handleCloseCard}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
              sx: {
                background:
                  'linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgba(22, 28, 36, 0.19) 100%)'
              }
            }}
          >
            <Fade in={openCard}>
              <Box sx={style}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    View {userSelected.firstName} {userSelected.lastName}'s card below
                  </Typography>
                  <IconButton onClick={handleCloseCard}>
                    <Tooltip title="Close">
                      <Icon icon={closeCircleOutline} />
                    </Tooltip>
                  </IconButton>
                </div>
                <WWCCardImage cardImage={userSelected.cardImage} />
              </Box>
            </Fade>
          </Modal>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openRegister}
            onClose={handleCloseRegister}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
              sx: {
                background:
                  'linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgba(22, 28, 36, 0.19) 100%)'
              }
            }}
          >
            <Fade in={openRegister}>
              <Box sx={style}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    Register a user by filling out the details below
                  </Typography>
                  <IconButton onClick={handleCloseRegister}>
                    <Tooltip title="Close">
                      <Icon icon={closeCircleOutline} />
                    </Tooltip>
                  </IconButton>
                </div>
                <Box sx={{ width: '100%', mt: 5 }}>
                  {step != 4 ? (
                    <Stepper activeStep={step} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel onClick={backStep} style={{ cursor: 'pointer' }}>
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  ) : null}
                </Box>
                <RegisterFormPortal
                  updateStep={updateStep}
                  step={step}
                  previousStep={previousStep}
                  organization={accountName}
                />
              </Box>
            </Fade>
          </Modal>

          <Container style={{ maxWidth: '100%' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Stack direction="row" alignItems="center">
                <Typography variant="h4" gutterBottom>
                  WWC {organizationName} Portal
                </Typography>
                <img src={img} style={{ width: 150, marginLeft: 50 }} />
                <div style={{ height: 100, paddingLeft: 15, paddingRight: 15, height: '100%' }}>
                  <Divider orientation="vertical" flexItem />
                </div>
                <img src={wwc} alt="login" style={{ width: 100 }} />
              </Stack>
              <div style={{ display: 'flex' }}>
                <Button
                  variant="outlined"
                  onClick={handleCopyOpen}
                  startIcon={<Icon icon={shareFill} color={color} />}
                  style={{ marginRight: 20, border: `1px solid ${color}`, color: color }}
                >
                  Share Link
                </Button>
                <Button
                  variant="contained"
                  component={RouterLink}
                  to="#"
                  startIcon={<Icon icon={plusFill} />}
                  style={{ backgroundColor: color }}
                >
                  Add User
                </Button>
              </div>
            </Stack>
            {dataSource.length === 0 ? (
              <CircularProgress />
            ) : (
              <Card>
                <WWCToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                  placeholder="Search..."
                  csvData={dataSource}
                  ageGroup={`WWC - ${accountName}`}
                  toggleFullscreen={toggleFullscreen}
                  fullTable={showFullTable}
                />

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <div style={!showFullTable ? { display: 'none' } : { display: 'block' }}>
                        <WWCListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={dataSource.length}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />

                        <TableBody>
                          {(rowsPerPage > 0
                            ? filteredUsers.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                            : dataSource
                          ).map((row) => {
                            /* eslint-disable camelcase */
                            const {
                              firstName,
                              lastName,
                              email,
                              employmentStatus,
                              employmentType,
                              position,
                              dob,
                              category,
                              exemptions,
                              datePositionReviewed,
                              dateReviewComplete,
                              actionTaken,
                              wwcCheckStatus,
                              expiryDate,
                              renewal_number,
                              startDate,
                              validationDates,
                              cardNumber
                            } = row;

                            const name = `${firstName} ${lastName}`;

                            return (
                              <TableRow hover tabIndex={-1}>
                                <TableCell padding="checkbox">
                                  <Checkbox />
                                </TableCell>
                                <TableCell component="th" scope="row" padding="none">
                                  <Stack direction="row" alignItems="center" spacing={2}>
                                    {/* <Avatar alt={name} src={avatarUrl} /> */}
                                    <Typography variant="subtitle2" noWrap>
                                      {name}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">{email}</TableCell>
                                <TableCell align="left">{dob}</TableCell>
                                <TableCell align="left">{employmentStatus}</TableCell>
                                <TableCell align="left">{employmentType}</TableCell>
                                <TableCell align="left">{position}</TableCell>
                                <TableCell align="left">{category}</TableCell>
                                <TableCell align="left">{renewal_number}</TableCell>
                                <TableCell align="left">{cardNumber}</TableCell>

                                <TableCell align="left">{expiryDate}</TableCell>
                                <TableCell align="center">{dateReviewComplete}</TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="right" style={{ display: 'flex' }}>
                                  <WWCMore />
                                  <Tooltip title="View Profile">
                                    <IconButton onClick={() => handleOpen(row)}>
                                      <Icon icon={viewMore} width={24} height={24} />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="View Card">
                                    <IconButton onClick={() => handleOpenCard(row)}>
                                      <Icon
                                        icon="ant-design:credit-card-outlined"
                                        width={24}
                                        height={24}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                        {isUserNotFound && (
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={12} sx={{ py: 5 }}>
                                <SearchNotFound searchQuery={filterName} />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </div>
                      <div style={showFullTable ? { display: 'none' } : { display: 'block' }}>
                        <WWCListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD_FULL}
                          rowCount={dataSource.length}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />

                        <TableBody>
                          {(rowsPerPage > 0
                            ? filteredUsers.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                            : dataSource
                          ).map((row) => {
                            /* eslint-disable camelcase */
                            const {
                              firstName,
                              lastName,
                              email,
                              employmentStatus,
                              employmentType,
                              position,
                              dob,
                              category,
                              exemptions,
                              datePositionReviewed,
                              dateReviewComplete,
                              actionTaken,
                              wwcCheckStatus,
                              expiryDate,
                              renewal_number,
                              startDate,
                              validationDates,
                              cardNumber
                            } = row;

                            const name = `${firstName} ${lastName}`;

                            return (
                              <TableRow hover tabIndex={-1}>
                                <TableCell padding="checkbox">
                                  <Checkbox />
                                </TableCell>
                                <TableCell component="th" scope="row" padding="none">
                                  <Stack direction="row" alignItems="center" spacing={2}>
                                    {/* <Avatar alt={name} src={avatarUrl} /> */}
                                    <Typography variant="subtitle2" noWrap>
                                      {name}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">{email}</TableCell>
                                <TableCell align="left" style={{ width: 200 }}>
                                  {dob}
                                </TableCell>
                                <TableCell align="left">{employmentStatus}</TableCell>
                                <TableCell align="left">{employmentType}</TableCell>
                                <TableCell align="left">{position}</TableCell>
                                <TableCell align="left">{category}</TableCell>
                                <TableCell align="left">{exemptions}</TableCell>
                                <TableCell align="left">{renewal_number}</TableCell>
                                <TableCell align="left">{cardNumber}</TableCell>

                                <TableCell align="left">{expiryDate}</TableCell>
                                <TableCell align="left">{startDate}</TableCell>
                                <TableCell style={{ width: 200 }} align="left">
                                  {wwcCheckStatus}
                                </TableCell>
                                <TableCell align="left">{actionTaken}</TableCell>
                                <TableCell align="left">{datePositionReviewed}</TableCell>

                                <TableCell align="left">{validationDates}</TableCell>
                                <TableCell align="left">{dateReviewComplete}</TableCell>
                                <TableCell align="right" style={{ display: 'flex' }}>
                                  <WWCMore />
                                  <Tooltip title="View Profile">
                                    <IconButton onClick={() => handleOpen(row)}>
                                      <Icon icon={viewMore} width={24} height={24} />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="View Card">
                                    <IconButton onClick={() => handleOpenCard(row)}>
                                      <Icon
                                        icon="ant-design:credit-card-outlined"
                                        width={24}
                                        height={24}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          {isUserNotFound && (
                            <TableBody>
                              <TableRow>
                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                  <SearchNotFound searchQuery={filterName} />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          )}
                        </TableBody>
                      </div>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[50, 100, 150]}
                  component="div"
                  count={filteredUsers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            )}
          </Container>
        </>
      )}
    </Page>
  );
};

export default WCCPortal;
