import { Avatar, Divider, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';

import cardOutline from '@iconify/icons-eva/credit-card-outline';
import { Icon } from '@iconify/react';

export default function WWCCardImage({ cardImage }) {
  return (
    <Stack direction="row" spacing={2} justifyContent="center">
      {cardImage != undefined ? (
        <img style={{ width: '50%', height: '50%' }} sx={{ mt: 1 }} src={cardImage} />
      ) : (
        <Icon icon={cardOutline} fontSize={150} />
      )}
    </Stack>
  );
}
