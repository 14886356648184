import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Stack,
  Link,
  Container,
  Typography,
  Divider,
  CircularProgress,
  Button
} from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import AuthSocial from '../components/authentication/AuthSocial';
import eaglesLogo from '../assets/img/eagles_logo_3.png';
import backgroundLogin from '../assets/img/background-login.svg';

import wwc from '../assets/img/wwc.png';
import { PortalLoginForm } from '../components/authentication/portalLogin';

import { getDatabase, ref, child, get, set, onValue } from 'firebase/database';
import app from '../config/database/firebase';
import { useEffect, useState } from 'react';
import { MotionContainer, varBounceIn } from '../components/animate';
import { Box } from '@mui/system';
import { motion } from 'framer-motion';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function PortalLogin() {
  const { slug } = useParams();
  console.log(slug);
  const [url, setUrl] = useState('');
  const [account, setAccount] = useState('');
  const [noAccount, setNoAccount] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const db = getDatabase(app);
    const playerRef = ref(db, `WWC/portalAccounts/${slug}`);
    onValue(playerRef, (snapshot) => {
      if (snapshot.exists()) {
        setAccount(snapshot.val());
        setLoading(false);
        console.log(snapshot.val());
      } else {
        setNoAccount(true);
      }
    });
  }, []);

  return (
    <RootStyle
      title={`Login | ${account.organizationName} WWC Check`}
      sx={{
        backgroundImage: `url(${backgroundLogin})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'right',
        backgroundPositionY: 200
      }}
    >
      {noAccount ? (
        <ContentStyle>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: 50
            }}
          >
            <MotionContainer initial="initial" open>
              <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
                <motion.div variants={varBounceIn}>
                  <Typography variant="h3" paragraph>
                    No Account found!
                  </Typography>
                </motion.div>
                <Typography sx={{ color: 'text.secondary' }}>
                  Sorry, we couldn’t find the account you’re looking for. Perhaps you’ve mistyped
                  the URL? Be sure to check your spelling.
                </Typography>

                <motion.div variants={varBounceIn}>
                  <Box
                    component="img"
                    src="/static/illustrations/illustration_404.svg"
                    sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
                  />
                </motion.div>

                <Button to="/wwc-register" size="large" variant="contained" component={RouterLink}>
                  Go to Register page
                </Button>
              </Box>
            </MotionContainer>
          </div>
        </ContentStyle>
      ) : (
        <Container maxWidth="sm">
          {loading ? (
            <ContentStyle>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingBottom: 50
                }}
              >
                <CircularProgress />
              </div>
            </ContentStyle>
          ) : (
            <ContentStyle>
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingBottom: 50
                  }}
                >
                  <img src={account.img} alt="logo" style={{ width: '25%' }} />
                  <div style={{ height: 100, paddingLeft: 15, paddingRight: 15, height: '100%' }}>
                    <Divider orientation="vertical" flexItem />
                  </div>
                  <img src={wwc} alt="login" style={{ width: '25%' }} />
                </div>
                <Stack sx={{ mb: 5 }}>
                  <Typography variant="h4" gutterBottom>
                    Sign in to your WWC Portal
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>
                    Enter your details below.
                  </Typography>
                </Stack>

                <PortalLoginForm slug={slug} accountEmail={account.email} />

                <MHidden width="smUp">
                  <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                    Don’t have an account?&nbsp;
                    <Link variant="subtitle2" component={RouterLink} to="register">
                      Get started
                    </Link>
                  </Typography>
                </MHidden>
              </>
            </ContentStyle>
          )}
        </Container>
      )}
    </RootStyle>
  );
}
