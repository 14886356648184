import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import shareFill from '@iconify/icons-eva/share-fill';
import messageCircleFill from '@iconify/icons-eva/message-circle-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Link,
  Card,
  Grid,
  Avatar,
  Typography,
  CardContent,
  Modal,
  Backdrop,
  Fade
} from '@mui/material';
// utils
//
import { useState } from 'react';

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)'
});

const TitleStyle = styled(Link)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical'
});

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2)
}));

const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(3),
  color: theme.palette.text.disabled
}));

const CoverImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  position: 'absolute',
  cursor: 'pointer'
});

// ----------------------------------------------------------------------

WWCCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number
};

export default function WWCCard({ post, index, handleClick }) {
  const { img, title, view, comment, share, color, createdAt, accountName } = post;
  const latestPostLarge = index === 0;
  const latestPost = index === 1 || index === 2;

  const POST_INFO = [
    { number: comment, icon: messageCircleFill },
    { number: view, icon: eyeFill },
    { number: share, icon: shareFill }
  ];

  let navigate = useNavigate();

  const navigatePortal = (info) => {
    console.log(info);
    navigate('/dashboard/wwc/portal', { replace: true, state: info });
  };

  return (
    <Grid item xs={4} sm={latestPostLarge ? 12 : 6} md={latestPostLarge ? 8 : 4}>
      <Card sx={{ position: 'relative' }} onClick={() => handleClick(post)}>
        <CardMediaStyle
          style={{ height: 179 }}
          sx={{
            ...((latestPostLarge || latestPost) && {
              pt: 'calc(100% * 4 / 3)',
              backgroundColor: color,
              transition: '0.2s ease',

              cursor: 'pointer',
              '&:hover': {
                backgroundColor: `${color}8c`,
                opacity: '0.3'
              },
              '&:after': {
                top: 0,
                content: "''",
                width: '100%',
                height: '100%',
                position: 'absolute'
              }
            }),
            ...(latestPostLarge && {
              pt: {
                xs: 'calc(100% * 4 / 3)',
                sm: 'calc(100% * 3 / 4.66)'
              }
            })
          }}
        >
          <CoverImgStyle
            alt={title}
            src={img}
            style={latestPostLarge ? { objectFit: 'contain' } : { objectFit: 'cover' }}
            sx={{
              transition: '0.23s ease',
              '&:hover': {
                opacity: '0.3'
              }
            }}
          />
        </CardMediaStyle>
      </Card>
    </Grid>
  );
}
