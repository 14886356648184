import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Link,
  Container,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  CircularProgress,
  Stack,
  Button
} from '@mui/material';
// layouts
// components
import Page from '../components/Page';
import { RegisterForm } from '../components/authentication/register';
import eaglesLogo from '../assets/img/eagles_logo_3.png';

import wwc from '../assets/img/wwc.png';
import { BlogPostCard } from 'src/components/_dashboard/blog';

import { getDatabase, ref, child, get, set, onValue } from 'firebase/database';
import app from '../config/database/firebase';
import WWCCard from 'src/components/wwc/WWCCard';
import leftArrowFill from '@iconify/icons-eva/arrow-left-fill';

import infoOutline from '@iconify/icons-eva/info-outline';
import { Icon } from '@iconify/react';
import bgRegister from '../assets/img/register-bg.jpg';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyleParent = styled('div')(({ theme }) => ({
  maxWidth: 500,
  margin: 'auto',
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: '91vh',
  flexDirection: 'column',
  padding: theme.spacing(5, 3),
  alignItems: 'center',
  marginTop: '3%',
  marginBottom: '3%',
  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  backgroundColor: '#ffffff',
  borderRadius: 10
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 500,
  margin: 'auto',
  position: 'relative',
  display: 'flex',
  minHeight: '91vh',
  flexDirection: 'column',
  padding: theme.spacing(5, 3),
  alignItems: 'center',
  marginTop: '3%',
  marginBottom: '3%',
  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  backgroundColor: '#ffffff',
  borderRadius: 10
}));

const ContentStyleChild = styled('div')(({ theme }) => ({
  maxWidth: 500,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: 10,
  paddingBottom: 10
}));

const OverlayBG = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100vh',
  backgroundColor: `${theme.palette.primary.main}90`
}));

const BackStyle = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'absolute'
}));

const steps = ['User Details', 'Card Information', 'Card Details', 'Card Picture'];

// ----------------------------------------------------------------------

export default function Register() {
  const [step, setStep] = useState(0);
  const [selectClub, SetSelectClub] = useState(true);
  const [accounts, setAccounts] = useState('');
  const [organization, setOrganization] = useState('');
  const [loading, setLoading] = useState(true);
  const [showInfo, setShowInfo] = useState(true);
  const updateStep = () => {
    setStep(step + 1);
  };
  const backStep = () => {
    if (step != 0) {
      setStep(step - 1);
    }
  };

  const previousStep = () => {
    setStep(step - 1);
  };

  const handleSelectOrganization = (value) => {
    setOrganization(value);
    console.log(value);
    SetSelectClub(false);
  };

  const dbRef = ref(getDatabase(app));

  useEffect(() => {
    const db = getDatabase(app);
    const playerRef = ref(db, `WWC/portalAccounts`);
    onValue(playerRef, (snapshot) => {
      if (snapshot.exists()) {
        const account = snapshot.val();
        const accountList = [];
        for (let key in account) {
          accountList.push({ key, ...account[key] });
        }
        setAccounts(accountList);
        setLoading(false);
      } else {
        console.log('No data available');
      }
    });
  }, []);

  const dataSource = Object.keys(accounts).map((dataIndex) => accounts[dataIndex]);

  console.log(dataSource);

  return (
    <RootStyle title="Register | Eagles CRM">
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: `url(${bgRegister})`,
          backgroundRepeat: 'repeat'
        }}
      >
        <OverlayBG style={{}}>
          {showInfo ? (
            <Container>
              <ContentStyleParent>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingBottom: 15
                  }}
                >
                  <img src={eaglesLogo} alt="login" style={{ width: '25%' }} />
                  <div style={{ height: 100, paddingLeft: 15, paddingRight: 15, height: '100%' }}>
                    <Divider orientation="vertical" flexItem />
                  </div>
                  <img src={wwc} alt="login" style={{ width: '25%' }} />
                </div>
                <ContentStyleChild>
                  <Typography variant="h3" align="center" sx={{ color: 'text', mt: 3 }}>
                    WWC Check Form
                  </Typography>
                  <Typography variant="body" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
                    Basketball WA values our Clubs, Members and Affiliates and wants to ensure that
                    the operating environment for all our junior members is safe. Protecting
                    children is a whole of community and whole of Basketball responsibility, and
                    there are many things Associations can do to make sure we are creating
                    child-safe and child-friendly environments.
                    <br />
                    <br />
                    Now is a great time to check and review your Associations policies and processes
                    including ensuring you are identifying those who are engaged by your Association
                    in child-related work i.e. WABL Coaches, Managers etc.{' '}
                    <strong>
                      The Working with Children’s (WWC) compliance unit is currently actively
                      auditing sporting organisations and has contacted Basketball WA to inform us
                      they will be working with some of our Associations.
                    </strong>
                    <br />
                    <br />
                    As a reminder to previous information we distributed, the WWCC unit have been
                    conducting{' '}
                    <Link href="https://www.eventbrite.com.au/o/working-with-children-screening-unit-33449292425">
                      {' '}
                      educational workshops
                    </Link>{' '}
                    that are targeted at sporting associations and clubs.
                    <br />
                    <br />
                  </Typography>
                </ContentStyleChild>
                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => setShowInfo(false)}
                >
                  Begin WWC Check
                </Button>
              </ContentStyleParent>
            </Container>
          ) : selectClub ? (
            <Container>
              <ContentStyle>
                <BackStyle>
                  <Tooltip title="Go to info">
                    <IconButton onClick={() => setShowInfo(true)}>
                      <Icon icon={infoOutline} />
                    </IconButton>
                  </Tooltip>
                </BackStyle>
                <Typography variant="h3" align="center" sx={{ color: 'text', mt: 3 }}>
                  WWC Check Form
                </Typography>
                <Typography variant="body" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
                  Select an Organization below to begin
                </Typography>
                <Grid container spacing={3} sx={{ mt: 3 }}>
                  {loading ? (
                    <Stack
                      style={{ width: '100%' }}
                      sx={{ mt: 5 }}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <CircularProgress />
                    </Stack>
                  ) : (
                    dataSource.map((accounts, index) => (
                      <WWCCard
                        key={accounts.id}
                        post={accounts}
                        index={index}
                        handleClick={handleSelectOrganization}
                      />
                    ))
                  )}
                </Grid>
              </ContentStyle>
            </Container>
          ) : (
            <Container>
              <ContentStyle>
                <BackStyle>
                  <Tooltip title="Back to Select Organization">
                    <IconButton onClick={() => SetSelectClub(true)}>
                      <Icon icon={leftArrowFill} />
                    </IconButton>
                  </Tooltip>
                </BackStyle>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingBottom: 50
                  }}
                >
                  <img src={organization.img} alt="login" style={{ width: '25%' }} />
                  <div style={{ height: 100, paddingLeft: 15, paddingRight: 15, height: '100%' }}>
                    <Divider orientation="vertical" flexItem />
                  </div>
                  <img src={wwc} alt="login" style={{ width: '25%' }} />
                </div>
                <Box sx={{ width: '100%' }}>
                  {step != 4 ? (
                    <Stepper activeStep={step} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel onClick={backStep} style={{ cursor: 'pointer' }}>
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  ) : null}
                </Box>

                <RegisterForm
                  updateStep={updateStep}
                  step={step}
                  previousStep={previousStep}
                  organization={organization.accountName}
                />
                <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
                  By registering, I agree to East Perth Eagles&nbsp;
                  <Link underline="always" sx={{ color: 'text.primary' }}>
                    Terms of Service
                  </Link>
                  &nbsp;and&nbsp;
                  <Link underline="always" sx={{ color: 'text.primary' }}>
                    Privacy Policy
                  </Link>
                  .
                </Typography>
              </ContentStyle>
            </Container>
          )}
        </OverlayBG>
      </div>
    </RootStyle>
  );
}
