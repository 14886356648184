import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import shop from '@iconify/icons-eva/shopping-bag-outline';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'coaches',
    path: '/dashboard/coaches',
    icon: getIcon(peopleFill)
  },
  {
    title: 'players',
    path: '/dashboard/players',
    icon: getIcon('bx:basketball')
  },
  {
    title: 'shooting machine',
    path: '/dashboard/blog',
    icon: getIcon('game-icons:basketball-basket')
  },
  {
    title: 'store',
    path: '/login',
    icon: getIcon(shop)
  },
  {
    title: 'WWC',
    path: '/dashboard/wwc',
    icon: getIcon('ant-design:credit-card-outlined')
  }
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];

export default sidebarConfig;
