import * as Yup from 'yup';
import { useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Link, useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Avatar,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  CircularProgress,
  Button,
  alertTitleClasses
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import moment from 'moment';

import cameraFilled from '@iconify/icons-ant-design/camera-filled';
import check from '@iconify/icons-ant-design/check';
import { green } from '@mui/material/colors';

import { getDatabase, ref as dbRef, child, get, set, onValue } from 'firebase/database';
import app from '../../../config/database/firebase';

// ----------------------------------------------------------------------

export default function RegisterForm({ updateStep, previousStep, step, organization }) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [employmentStatus, setEmploymentStatus] = useState('');
  const [employmentType, setEmploymentType] = useState('');
  const [wwcCheckStatus, setWWCCheckStatus] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [loadingImg, setLoadingImg] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [enabled, setEnabled] = useState(true);
  const inputFileRef = useRef(null);
  console.log(organization);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    dob: Yup.string().required('Date of birth is required'),
    employmentType: Yup.string().required('Password is required'),
    employmentStatus: Yup.string().required('Password is required'),
    position: Yup.string().required('Position is required'),
    category: Yup.string().required('Category is required'),
    datePositionReviewed: Yup.string().required('Date PositionReviewed is required'),
    validationDates: Yup.string().required('Validation Dates is required'),
    cardNumber: Yup.string().required('Card Number is required'),
    renewalNumber: Yup.string().required('Renewal Number is required'),
    expiryDate: Yup.string().required('Expiry Date is required')
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      dob: '',
      position: '',
      category: '',
      exemptions: '',
      actionTaken: '',
      datePositionReviewed: '',
      validationDates: '',
      renewalNumber: '',
      cardNumber: '',
      expiryDate: ''
    },
    onSubmit: (values) => {
      submit(values);
    }
  });

  const { errors, touched, isSubmitting, getFieldProps, values } = formik;

  const getImgOnClick = () => {
    inputFileRef.current.click();
  };

  const uploadCard = (name, card) => {
    console.log('clicked');

    const storage = getStorage();
    const now = moment.now();
    const cardRef = ref(storage, `wwc/${now}`);
    uploadBytes(cardRef, selectedImage)
      .then(async () => {
        const imgURL = await getDownloadURL(ref(storage, `wwc/${now}`));
        console.log(`details: ${name}${card}`);

        set(dbRef(db, `WWC/users/${name}${card}/cardImage`), imgURL).then(() => {
          setLoadingImg(false);
          updateStep();
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const db = getDatabase(app);

  const submit = (values) => {
    setLoadingImg(true);
    console.log('here');
    console.log(values);
    const { firstName, lastName, cardNumber } = values;

    set(dbRef(db, `WWC/users/${firstName}${cardNumber}`), {
      ...values,
      employmentStatus,
      employmentType,
      wwcCheckStatus,
      organization
    })
      .then(() => {
        uploadCard(firstName, cardNumber);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate style={{ width: '100%' }}>
        <Stack spacing={3} style={{ marginTop: 50 }}>
          {/* //DETAIL PICTURE */}
          {step === 0 ? (
            <>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="First name"
                  onChange={(e) => setFirstName(e.target.value)}
                  {...getFieldProps('firstName')}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />

                <TextField
                  fullWidth
                  label="Last name"
                  onChange={(e) => setLastName(e.target.value)}
                  {...getFieldProps('lastName')}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Stack>
              <TextField
                fullWidth
                autoComplete="username"
                type="email"
                label="Email address"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />

              <TextField
                fullWidth
                type="date"
                {...getFieldProps('dob')}
                error={Boolean(touched.dob && errors.dob)}
                helperText="Date of Birth"
              />
            </>
          ) : //CARD DETAILS
          step === 1 ? (
            <>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Employment Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={employmentStatus}
                    label="Employment Status"
                    onChange={(e) => setEmploymentStatus(e.target.value)}
                    error={Boolean(touched.employmentStatus && errors.employmentStatus)}
                    helperText={touched.employmentStatus && errors.employmentStatus}
                  >
                    <MenuItem value="Current">Current</MenuItem>
                    <MenuItem value="Former">Former</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Employment Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={employmentType}
                    label="Employment Type"
                    defaultValue="Volunteer"
                    onChange={(e) => setEmploymentType(e.target.value)}
                    error={Boolean(touched.employmentType && errors.employmentType)}
                    helperText={touched.employmentType && errors.employmentType}
                  >
                    <MenuItem value="Paid Employee">Paid Employee</MenuItem>
                    <MenuItem value="Volunteer">Volunteer</MenuItem>
                    <MenuItem value="Student">Student</MenuItem>
                    <MenuItem value="Managerial Officer">Managerial Officer</MenuItem>
                    <MenuItem value="Self-Employed">Self-Employed</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="Position"
                  {...getFieldProps('position')}
                  error={Boolean(touched.position && errors.position)}
                  helperText="i.e. WABL Coach"
                />

                <TextField
                  fullWidth
                  label="Category"
                  {...getFieldProps('category')}
                  error={Boolean(touched.category && errors.category)}
                  helperText={touched.category && errors.category}
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField fullWidth label="Exemptions" {...getFieldProps('exemptions')} />

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">WWC Check Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={wwcCheckStatus}
                    label="WWC Check Status"
                    onChange={(e) => setWWCCheckStatus(e.target.value)}
                  >
                    <MenuItem value="1. Holds current WWC Card">1. Holds current WWC Card</MenuItem>
                    <MenuItem value="2. Application lodged">2. Application lodged</MenuItem>
                    <MenuItem value="3. Interim Negative Notice">
                      3. Interim Negative Notice
                    </MenuItem>
                    <MenuItem value="4. Negative Notice">4. Negative Notice</MenuItem>
                    <MenuItem value="5. Application withdrawn">5. Application withdrawn</MenuItem>
                    <MenuItem value="6. WWC Check Renewal lodged">
                      6. WWC Check Renewal lodged
                    </MenuItem>
                    <MenuItem value="7. Not in child-related work">
                      7. Not in child-related work
                    </MenuItem>
                  </Select>
                </FormControl>
              </Stack>

              <TextField fullWidth label="Action Taken" {...getFieldProps('actionTaken')} />

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  type="date"
                  helperText="Date positioned reviewed"
                  {...getFieldProps('datePositionedReviewed')}
                />

                <TextField
                  fullWidth
                  type="date"
                  helperText="Validation Dates"
                  {...getFieldProps('validationDates')}
                />
              </Stack>
            </>
          ) : step === 2 ? (
            <>
              <TextField
                fullWidth
                label="WWC Card Number"
                onChange={(e) => setCardNumber(e.target.value)}
                {...getFieldProps('cardNumber')}
                error={Boolean(touched.cardNumber && errors.cardNumber)}
                helperText={touched.cardNumber && errors.cardNumber}
              />

              <TextField fullWidth label="Renewal Number" {...getFieldProps('renewalNumber')} />

              <TextField
                fullWidth
                helperText="Expiry Date"
                type="date"
                {...getFieldProps('expiryDate')}
              />
            </>
          ) : //CARD PICTURE

          step === 3 ? (
            <>
              <Link
                to="#"
                onClick={getImgOnClick}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Avatar style={{ width: 200, height: 200 }}>
                  {selectedImage != null ? (
                    <img src={URL.createObjectURL(selectedImage)} />
                  ) : (
                    <Icon icon={cameraFilled} fontSize={80} />
                  )}
                </Avatar>
                <Typography
                  variant="body2"
                  align="center"
                  sx={{ color: 'text.secondary', mt: 3 }}
                  style={{ textDecoration: 'no' }}
                >
                  <strong>Upload photo of card to complete check</strong>
                </Typography>
              </Link>
              <input
                type="file"
                name="myImage"
                ref={inputFileRef}
                style={{ display: 'none' }}
                onChange={(event) => {
                  console.log(event.target.files[0]);
                  setSelectedImage(event.target.files[0]);
                  setEnabled(false);
                }}
              />
            </>
          ) : (
            <div
              style={{
                textDecoration: 'none',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Typography
                variant="h3"
                align="center"
                sx={{ color: 'text.primary', mb: 3 }}
                style={{ textDecoration: 'no' }}
              >
                WWC Form Completed!
              </Typography>
              <Avatar style={{ width: 200, height: 200 }} sx={{ bgcolor: green[500], mb: 5 }}>
                <Icon icon={check} fontSize={130} />
              </Avatar>

              <Button fullWidth variant="outlined" style={{ textTransform: 'none' }}>
                <a
                  href="https://www.eastpertheagles.com.au"
                  style={{
                    textDecoration: 'none',
                    color: '#3c57a6'
                  }}
                >
                  Return to eastpertheagles.com.au
                </a>
              </Button>
            </div>
          )}

          {step != 4 ? (
            step === 3 ? (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                disabled={enabled}
                loading={loadingImg}
              >
                Complete WWC Check
              </LoadingButton>
            ) : (
              <LoadingButton
                fullWidth
                size="large"
                // type="submit"
                variant="contained"
                // loading={isSubmitting}
                onClick={updateStep}
              >
                Next Step
              </LoadingButton>
            )
          ) : null}

          {step != 0 ? (
            step != 4 ? (
              <Button variant="text" onClick={previousStep}>
                Previous Step
              </Button>
            ) : null
          ) : null}
        </Stack>
      </Form>
    </FormikProvider>
  );
}
