import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyCJ3LpKZRMr8nE_XwspW9s3a_em82hJnrM',
  authDomain: 'east-perth-eagles-mobile-app.firebaseapp.com',
  databaseURL: 'https://east-perth-eagles-mobile-app-default-rtdb.firebaseio.com',
  projectId: 'east-perth-eagles-mobile-app',
  storageBucket: 'east-perth-eagles-mobile-app.appspot.com',
  messagingSenderId: '128402795045',
  appId: '1:128402795045:web:6fd54273f4ed65934eedcc',
  measurementId: 'G-F5LTNZP3QV'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
