import { Avatar, Divider, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';

import cardOutline from '@iconify/icons-eva/credit-card-outline';
import { Icon } from '@iconify/react';

export default function WWCProfile({ profile }) {
  const {
    firstName,
    lastName,
    email,
    employmentStatus,
    employmentType,
    position,
    dob,
    category,
    exemptions,
    datePositionReviewed,
    dateReviewComplete,
    actionTaken,
    wwcCheckStatus,
    expiryDate,
    cardImage,
    renewalNumber,
    startDate,
    validationDates,
    cardNumber
  } = profile;
  return (
    <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
      <Stack sx={{ pr: 3 }} style={{ width: '60%' }}>
        <Avatar sx={{ width: 250, height: 250, mt: 3, pl: 5 }} src={cardImage}>
          {cardImage != undefined ? null : <Icon icon={cardOutline} fontSize={150} />}
        </Avatar>
        <Typography id="transition-modal-title" variant="h6" component="h2" sx={{ mt: 5 }}>
          User Details
        </Typography>
        <Divider />

        <Stack direction="row" justifyContent="space-between">
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            Name:
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            <strong>
              {firstName} {lastName}
            </strong>
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            Email:
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            <strong>{email}</strong>
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            Date of Birth:
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            <strong>{moment(dob).format('DD MMM YYYY')}</strong>
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            Position:
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            <strong>{position}</strong>
          </Typography>
        </Stack>
      </Stack>
      <Stack sx={{ width: '100%', pl: 5 }}>
        <Typography id="transition-modal-title" variant="h6" component="h2">
          Working with children card details
        </Typography>
        <Divider />
        <Stack direction="row" justifyContent="space-between">
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            Card Number:
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            <strong>{cardNumber}</strong>
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            Card Number:
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            <strong>{cardNumber}</strong>
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            Expiry Date:
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            <strong>{moment(expiryDate).format('DD MMM YYYY')}</strong>
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            Renewal Number:
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            <strong>{renewalNumber}</strong>
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            Employment Status:
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            <strong>{employmentStatus}</strong>
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            Employment Type:
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            <strong>{employmentType}</strong>
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            Validation Dates:
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            <strong>{validationDates}</strong>
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            Action Taken:
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            <strong>{actionTaken}</strong>
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            Exemptions:
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            <strong>{exemptions}</strong>
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            Category:
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            <strong>{category}</strong>
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            Date Position Reviewed:
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            <strong>{datePositionReviewed}</strong>
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            WWC Check Status:
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            <strong>{wwcCheckStatus}</strong>
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            Date Submitted:
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 3 }}>
            <strong>{dateReviewComplete}</strong>
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
