import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Snackbar,
  Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getAuth, signInWithEmailAndPassword, updateEmail, updatePassword } from 'firebase/auth';
import app from '../../../config/database/firebase';
import { getDatabase, ref, child, get, onValue } from 'firebase/database';

// ----------------------------------------------------------------------

export default function PortalLoginForm({ slug, accountEmail }) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Error signing in. Please try again');
  // const [email, setEmail] = useState('');

  // const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  // const UpdateEmail = (event) => setEmail(event.target.value);
  // const UpdatePass = (event) => setPassword(event.target.value);
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      login(values);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const auth = getAuth(app);

  const [members, setMembers] = useState('');

  const dbRef = ref(getDatabase(app));

  get(child(dbRef, `WWC/portalAccounts`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        setMembers({
          ...snapshot.val()
        });
      } else {
        console.log('No data available');
      }
    })
    .catch((error) => {
      console.error(error);
    });

  const dataSource = Object.keys(members).map((dataIndex) => members[dataIndex]);

  const login = (values) => {
    setLoading(true);

    const { email, password } = values;
    console.log(email, password, slug);

    if (email === accountEmail) {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log('Signed In');
          setLoading(false);
          navigate(`/wwc-portal/${slug}/dashboard`, { replace: true });
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          if (errorCode === 'auth/wrong-password') {
            setErrorMsg('Incorrect Credentials. Please try again');
          }

          setOpen(true);
          setLoading(false);
        });
    } else {
      setErrorMsg('Account not found.');
      setOpen(true);
      setLoading(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
          Login
        </LoadingButton>
      </Form>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity="error">{errorMsg}</Alert>
      </Snackbar>
    </FormikProvider>
  );
}
