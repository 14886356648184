import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import roundDownload from '@iconify/icons-ic/round-download';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Button,
  Menu,
  MenuItem
} from '@mui/material';
import chevronUpFill from '@iconify/icons-eva/chevron-up-fill';
import chevronDownFill from '@iconify/icons-eva/chevron-down-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import { CSVDownload, CSVLink } from 'react-csv';
import { useState } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 250,
  marginRight: 20,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

PlayerSearchToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

const SORT_BY_OPTIONS = [
  { value: 'first_name', label: 'First Name' },
  { value: 'last_name', label: 'Last Name' },
  { value: 'number', label: 'Number' },
  { value: 'team', label: 'Team' }
];

const FILTER_BY_OPTIONS = [
  { value: 'Select Team', label: 'Select Team' },
  { value: 'Division 1', label: 'Division 1' },
  { value: 'Division 2', label: 'Division 2' },
  { value: 'Division 3', label: 'Division 3' },
  { value: 'Division 4', label: 'Division 4' },
  { value: 'Division 5', label: 'Division 5' },
  { value: 'Development', label: 'Development' },
  { value: 'No Team', label: 'No Team' }
];

export default function PlayerSearchToolbar({
  numSelected,
  filterName,
  onFilterName,
  placeholder,
  csvData,
  ageGroup,
  orderBy,
  handleSearchBy,
  sortTeam
}) {
  const [open, setOpen] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const [filterSearch, setFilterSearch] = useState('First Name');
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleClose = (option) => {
    if (option.label != undefined) {
      setFilterSearch(option.label);
      handleSearchBy(option.value);
    }
    setOpen(null);
  };

  const handleCloseMenu = (option) => {
    setOpenMenu(null);
    sortTeam(option.value);
    setFilterSearch('Team');
    console.log(option.value);
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <div>
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder={`Search player ${filterSearch.toLowerCase()}`}
            startAdornment={
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />

          <Button
            color="inherit"
            disableRipple
            onClick={handleOpen}
            endIcon={<Icon icon={open ? chevronUpFill : chevronDownFill} />}
          >
            Search by:&nbsp;
            <Typography component="span" variant="subtitle2" sx={{ color: 'text.secondary' }}>
              {filterSearch}
            </Typography>
          </Button>
          <Menu
            keepMounted
            anchorEl={open}
            open={Boolean(open)}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {SORT_BY_OPTIONS.map((option) => (
              <MenuItem
                key={option.value}
                selected={option.value === 'newest'}
                onClick={() => handleClose(option)}
                sx={{ typography: 'body2' }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      ) : (
        <div>
          {/* <Tooltip title="Filter list"> */}
          <Tooltip title="Reset Filter">
            <IconButton onClick={() => sortTeam('')}>
              <Icon icon={refreshFill} />
            </IconButton>
          </Tooltip>
          <IconButton onClick={handleOpenMenu}>
            <a>
              <Icon icon={roundFilterList} />
            </a>
          </IconButton>
          <Menu
            keepMounted
            anchorEl={openMenu}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {FILTER_BY_OPTIONS.map((option) => (
              <MenuItem
                key={option.value}
                selected={option.value === 'newest'}
                onClick={() => handleCloseMenu(option)}
                sx={{ typography: 'body2' }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
          {/* </Tooltip> */}
          <Tooltip title="Download current list">
            <IconButton>
              <CSVLink data={csvData} style={{ color: '#637381' }} filename={`${ageGroup}.csv`}>
                <Icon icon={roundDownload} />
              </CSVLink>
            </IconButton>
          </Tooltip>
        </div>
      )}
    </RootStyle>
  );
}
